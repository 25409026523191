import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
const apiKSFBaseUrl = environment.apiKSFBase;
const apiCoreBaseUrl = environment.apiCoreBase;
const apiKSFServer = environment.apiKSFBase;
@Injectable()
export class ApiService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: AuthService) { }

  options = {
    headers: new HttpHeaders({
      Authorization: this.authService.getAuthorizationHeaderValue(),
      'Content-Type': 'application/json',
    })
  };
  pathUrl = null;

  apiTesst(): Observable<any> {
    return this.http
      .get<any>(`https://printer.sunshinegroup.vn:8080/api/printer`);
  }

  // UInvWork

  getParameterPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/work/GetParameterPage?` + queryParams, this.options);
  }

  getInvParameter(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/work/getInvParameter?` + queryParams, this.options);
  }

  setInvParameter(queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v2/work/setInvParameter`, queryParams, this.options);
  }

  getCustObjectList(queryParams): Observable<any> {
    return this.http.get<any>(`${apiKSFBaseUrl}/api/v2/work/GetObjectList?` + queryParams, this.options);
  }

  getWorkflowPage(queryParams): Observable<any> {
    return this.http.get<any>(`${apiKSFBaseUrl}/api/v2/work/GetWorkflowPage?` + queryParams, this.options);
  }

  getWorkflowInfo(queryParams): Observable<any> {
    return this.http.get<any>(`${apiKSFBaseUrl}/api/v2/work/GetWorkflowInfo?` + queryParams, this.options);
  }

  setWorkSubmit(queryParams): Observable<any> {
    return this.http.post<any>(`${apiKSFBaseUrl}/api/v2/work/SetWorkSubmit`, queryParams, this.options);
  }

  setWorkApprove(queryParams): Observable<any> {
    return this.http.put<any>(`${apiKSFBaseUrl}/api/v2/work/SetWorkApprove`, queryParams, this.options);
  }

  getIntroPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/work/GetIntroPage?` + queryParams, this.options);
  }

  getIntroInfo(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/work/GetIntroInfo?` + queryParams, this.options);
  }

  getHolidayPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/work/GetHolidayPage?` + queryParams, this.options);
  }

  getObjectList(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/work/GetObjectList?` + queryParams, this.options);
  }

  getColumnObjectByLink(url): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}` + url, this.options);
  }

  delIntroInfo(queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/work/DelIntroInfo?` + queryParams, this.options);
  }
  

  // UInvProduct

  getProductFilter(): Observable<any> {
    return this.http.get<any>(`${apiKSFBaseUrl}/api/v2/product/GetProductFilter`, this.options);
  }

  getProductPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/product/GetProductPage?` + queryParams, this.options);
  }


  getProdRoomPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/product/GetProdRoomPage?` + queryParams, this.options);
  }

  getProducInfo(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/product/GetProducInfo?` + queryParams, this.options);
  }

  getProductList(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/product/GetProductList?` + queryParams, this.options);
  }

  getProductOrderPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/product/GetProductOrderPage?` + queryParams, this.options);
  }

  getProjectList(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/product/GetProjectList?` + queryParams, this.options);
  }

  delProduct(queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/product/DelProduct?` + queryParams, this.options);
  }

  setProductCancel(queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/product/SetProductCancel?` + queryParams, this.options);
  }

  setProductInfo(queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v2/product/SetProductInfo`, queryParams, this.options);
  }

  setProductSubmit(queryParams): Observable<any> {
    return this.http.put(`${apiKSFBaseUrl}/api/v2/product/SetProductSubmit`, queryParams, this.options);
  }

  //UInvOwner

  getOwnerList(): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/owner/GetOwnerList`, this.options);
  }

  getOwnerPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/owner/GetOwnerPage?` + queryParams, this.options);
  }

  getOwnerInfo(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/owner/GetOwnerInfo?` + queryParams, this.options);
  }

  setOwnerInfo(params): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v2/owner/SetOwnerInfo`, params, this.options);
  }

  setOwnerStatus(params): Observable<any> {
    return this.http.put(`${apiKSFBaseUrl}/api/v2/owner/SetOwnerStatus`, params, this.options);
  }

  delOwnerInfo(queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/owner/DelOwnerInfo?` + queryParams, this.options);
  }

  // ksInvConvert

  getOpenConvertPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/convert/GetOpenConvertPage?` + queryParams, this.options);
  }

  GetOrderBondContPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/order/GetOrderBondContPage?` + queryParams, this.options);
  }

  getKssBondUser(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v1/kinvuser/GetKssBondUser?` + queryParams, this.options);
  }

  GetKsfBondUser(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v1/kinvuser/GetKsfBondUser?` + queryParams, this.options);
  }

  setKssBond(queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v1/kinvuser/setKssBond` , queryParams, this.options);
  }

  
  setKsfBond(queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v1/kinvuser/setKsfBond` , queryParams, this.options);
  }

  GetKSFLiquidationConvert(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v1/kinvuser/GetKSFLiquidationConvert?soi_code=${queryParams.c_APPENDIX_NO}`, this.options);
  }

  getConvertTransactionPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/convert/GetConvertTransactionPage?` + queryParams, this.options);
  }

  getOpenConvertInfo(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/convert/GetOpenConvertInfo?` + queryParams, this.options);
  }

  getOpenContList(): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/convert/GetOpenContList`, this.options);
  }

  delOpenConvertInfo(queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/convert/DelOpenConvertInfo?` + queryParams, this.options);
  }

  setOpenConvertInfo(queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v2/convert/SetOpenConvertInfo`, queryParams, this.options);
  }

  setOpenConvertStatus(queryParams): Observable<any> {
    return this.http.put(`${apiKSFBaseUrl}/api/v2/convert/SetOpenConvertStatus`, queryParams, this.options);
  }

  setConvertTransApprove(queryParams): Observable<any> {
    return this.http.put(`${apiKSFBaseUrl}/api/v2/convert/SetConvertTransApprove`, queryParams, this.options);
  }

  setConvertPayOnHalfApprove(queryParams): Observable<any> {
    return this.http.put(`${apiKSFBaseUrl}/api/v2/convert/SetConvertPayOnHalfApprove`, queryParams, this.options);
  }

  // UInvOpen

  getOpenPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/open/GetOpenPage?` + queryParams, this.options);
  }

  getOpenInfo(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/open/GetOpenInfo?` + queryParams, this.options);
  }

  setOpenInfo(params): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v2/open/SetOpenInfo`, params, this.options);
  }

  delOpenInfo(params): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/open/DelOpenInfo?` + params, this.options);
  }

  setOpenStatus(params): Observable<any> {
    return this.http.put(`${apiKSFBaseUrl}/api/v2/open/SetOpenStatus`, params, this.options);
  }

  getOpenDetail(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/open/GetOpenDetail?` + queryParams, this.options);
  }

  getOpenPolicyPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/open/GetOpenPolicyPage?` + queryParams, this.options);
  }

  getOpenVoucherPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/open/GetOpenVoucherPage?` + queryParams, this.options);
  }

  getOpenVoucher(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/openvoucher/GetOpenVoucher?` + queryParams, this.options);
  }
  
  getOpenRoomPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/open/GetOpenRoomPage?` + queryParams, this.options);
  }

  delOpenVoucher2(queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/openvoucher/DelOpenVoucher?` + queryParams, this.options);
  }
  
  getOpenScheme(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/open/GetOpenScheme?` + queryParams, this.options);
  }

  getOpenTenor(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/open/getOpenTenor?` + queryParams, this.options);
  }

  delOpenTenor(queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/open/DelOpenTenor?` + queryParams, this.options);
  }

  delOpenPolicy(queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/open/DelOpenPolicy?` + queryParams, this.options);
  }
  
  delOpenVoucher(queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/open/DelOpenVoucher?` + queryParams, this.options);
  }

  setOpenTenor(queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v2/open/SetOpenTenor` , queryParams, this.options);
  }

  setOpenScheme(urlApiSave,queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v2/open/${urlApiSave}`, queryParams, this.options);
  }

  getOpenList(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/open/GetOpenList?` + queryParams, this.options);
  }

  setOpenSubmit(queryParams): Observable<any> {
    return this.http.put(`${apiKSFBaseUrl}/api/v2/open/SetOpenSubmit`, queryParams, this.options);
  }

  getOpenVoucherList(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/open/GetOpenVoucherList?${queryParams}`, this.options);
  }

  getOpenSchemes(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/open/GetOpenSchemes?${queryParams}`, this.options);
  }

  // UInvConfig 

  getFormViewPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v1/config/GetFormViewPage?` + queryParams, this.options);
  }

  setGroupInfo(queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v1/config/SetGroupInfo`, queryParams, this.options);
  }

  getGroupInfo(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v1/config/GetGroupInfo?` + queryParams, this.options);
  }

  getGridViewPage(url, queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v1/config/${url}?` + queryParams, this.options);
  }

  setFormViewInfo(queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v1/config/SetFormViewInfo` , queryParams, this.options);
  }

  setGridViewInfo(url, queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v1/config/${url}` , queryParams, this.options);
  }

  delFormViewInfo(url, queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v1/config/${url}?` + queryParams, this.options);
  }

  delGridViewInfo(queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/config/DelGridViewInfo?` + queryParams, this.options);
  }



  //UInvCooperatorShow

  getCooperatorPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/cooperator/GetCooperatorPage?` + queryParams, this.options);
  }

  getCooperatorInfo(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/cooperator/GetCooperatorInfo?` + queryParams, this.options);
  }

  delCooperatorInfo(queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/cooperator/DelCooperatorInfo?` + queryParams, this.options);
  }

  setCooperatorInfo(queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v2/cooperator/SetCooperatorInfo`, queryParams, this.options);
  }

  setCooperatorStatus(queryParams): Observable<any> {
    return this.http.put(`${apiKSFBaseUrl}/api/v2/cooperator/SetCooperatorStatus`, queryParams, this.options);
  }

  getCooperatorList(): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/cooperator/GetCooperatorList`, this.options);
  }

  // UInvTransaction

  setTransactionApprove(queryParams): Observable<any> {
    return this.http.put(`${apiKSFBaseUrl}/api/v2/transaction/SetTransactionApprove`, queryParams, this.options);
  }

  setPayOnHalfApprove(queryParams): Observable<any> {
    return this.http.put(`${apiKSFBaseUrl}/api/v2/transaction/SetPayOnHalfApprove`, queryParams, this.options);
  }

  setNoPayApprovedOfInterest(queryParams): Observable<any> {
    return this.http.put(`${apiKSFBaseUrl}/api/v2/interest/SetNoPayApprovedOfInterest`, queryParams, this.options);
  }

  setNoPayApprovedOfFinal(queryParams): Observable<any> {
    return this.http.put(`${apiKSFBaseUrl}/api/v2/final/SetNoPayApprovedOfFinal`, queryParams, this.options);
  }

  setTransactionInfo(queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v2/transaction/SetTransactionInfo`, queryParams, this.options);
  }

  setTransactionNotify(queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v2/transaction/SetTransactionNotify`, queryParams, this.options);
  }

  getTransactionPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/transaction/GetTransactionPage?` + queryParams, this.options);
  }

  getTransactionInfo(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/transaction/GetTransactionInfo?` + queryParams, this.options);
  }

  getTransBankPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/transaction/GetTransBankPage?` + queryParams, this.options);
  }

  getTransBankPayonPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/transaction/GetTransBankPayonPage?` + queryParams, this.options);
  }

  getTransAccounts(): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/transaction/GetTransAccounts`, this.options);
  }

  getTransBankAccountPage(queryParams): Observable<any> {
    return this.http.put(`${apiKSFBaseUrl}/api/v2/transaction/GetTransBankAccountPage`, queryParams, this.options);
  }

  delTransaction(queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/transaction/DelTransaction?` + queryParams, this.options);
  }

  setTransactionCancel(queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/transaction/SetTransactionCancel?` + queryParams, this.options);
  }

  // UInvExchange

  setExchange(queryParams): Observable<any> {
    return this.http.put(`${apiKSFBaseUrl}/api/v2/exchange/SetExchange`, queryParams, this.options);
  }

  setExchangeApprove(queryParams): Observable<any> {
    return this.http.put(`${apiKSFBaseUrl}/api/v2/exchange/SetExchangeApprove`, queryParams, this.options);
  }

  setExchangeCancel(queryParams): Observable<any> {
    return this.http.put(`${apiKSFBaseUrl}/api/v2/exchange/SetExchangeCancel`, queryParams, this.options);
  }

  getExchangePage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/exchange/GetExchangePage?` + queryParams, this.options);
  }

  getExchangeInfo(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/exchange/GetExchangeInfo?` + queryParams, this.options);
  }

  delExchange(queryParams): Observable<any> {
    return this.http.delete(`${apiKSFBaseUrl}/api/v2/exchange/DelExchange?` + queryParams, this.options);
  }

  getListMenuByUserId(userId, webId): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/corewebmanager/ClientMenuGetListByUserId?` +
      `userId=${userId}&webId=${webId}`, this.options)
  }

  getCustCoporatePage(url,queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/customer/${url}?` + queryParams, this.options)
  }

  //UInvOrderBook

  getOrderBookPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/book/GetOrderBookPage?${queryParams}`, this.options);
  }

  getOrderBookInfo(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/book/GetOrderBookInfo?${queryParams}`, this.options);
  }

  setOrderBookDraft(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/book/SetOrderBookDraft`, queryParams, this.options);
  }

  setOrderBookInfo(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/book/SetOrderBookInfo`, queryParams, this.options);
  }

  delOrderBook(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/book/DelOrderBook?${queryParams}`, this.options);
  }

  setOrderBookStatus(params: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/book/SetOrderBookStatus`, params, this.options);
  }

  setOrderBookApprove(params: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/book/SetOrderBookApprove`, params, this.options);
  }


  //UInvOrder
  setOrderLockNotify(params: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/order/SetOrderLockNotify`, params, this.options);
  }

  setBondLiqSubmit(params: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/order/SetBondLiqSubmit`, params, this.options);
  }

  setOrderMetaUpload(params: any): Observable<any> {
    this.pathUrl = this.router.url
    return this.http.post(`${apiKSFServer}/api/v2/${this.pathUrl.includes('hop-dong-chuyen-doi/') ? 'orderconvert' : 'order'}/SetOrderMetaUpload`, params, this.options);
  }

  setContract(params: any): Observable<any> {
    this.pathUrl = this.router.url
    return this.http.put(`${apiKSFServer}/api/v2/${this.pathUrl.includes('hop-dong-chuyen-doi/') ? 'orderconvert' : 'order'}/SetContract`, params, this.options);
  }

  setAppendixLiquidation(params: any): Observable<any> {
    this.pathUrl = this.router.url
    return this.http.put(`${apiKSFServer}/api/v2/${this.pathUrl.includes('hop-dong-chuyen-doi/') ? 'orderconvert' : 'order'}/SetAppendixLiquidation`, params, this.options);
  }

  setOrderUpOnline(params: any): Observable<any> {
    this.pathUrl = this.router.url
    return this.http.put(`${apiKSFServer}/api/v2/${this.pathUrl.includes('hop-dong-chuyen-doi/') ? 'orderconvert' : 'order'}/SetOrderUpOnline`, params, this.options);
  }

  setOrderVoucher(params: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/ordervoucher/setOrderVoucher`, params, this.options);
  }

  setSiptOrderApproveReg(params: any): Observable<any> {
    this.pathUrl = this.router.url
    return this.http.post(`${apiKSFServer}/api/v2/${this.pathUrl.includes('hop-dong-chuyen-doi/') ? 'orderconvert' : 'order'}/SetOrderApproveReg`, params, this.options);
  }
  
  setOrderHold(params: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/orderhold/SetOrderHold`, params, this.options);
  }

  getContractFile(queryParams: any): Observable<any> {
    this.pathUrl = this.router.url
    return this.http.get(`${apiKSFServer}/api/v2/${this.pathUrl.includes('hop-dong-chuyen-doi/') ? 'orderconvert' : 'order'}/GetContractFile?${queryParams}`, this.options);
  }

  getOrderHoldInfo(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/orderhold/GetOrderHoldInfo?${queryParams}`, this.options);
  }

  getOrderContInfo(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/ordercont/getOrderContInfo?${queryParams}`, this.options);
  }

  delOrderVoucher(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/ordervoucher/DelOrderVoucher?${queryParams}`, this.options);
  }

  delOrderRoom(queryParams: any): Observable<any> {
    this.pathUrl = this.router.url
    return this.http.delete(`${apiKSFServer}/api/v2/${this.pathUrl.includes('hop-dong-chuyen-doi/') ? 'orderconvert' : 'order'}/DelOrderRoom?${queryParams}`, this.options);
  }

  getOrderVoucher(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/ordervoucher/GetOrderVoucher?${queryParams}`, this.options);
  }

  getOrderVoucherPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/ordervoucher/GetOrderVoucherPage?${queryParams}`, this.options);
  }

  getOrderRoomSelect(queryParams: any): Observable<any> {
    this.pathUrl = this.router.url
    return this.http.get(`${apiKSFServer}/api/v2/${this.pathUrl.includes('hop-dong-chuyen-doi/') ? 'orderconvert' : 'order'}/GetOrderRoomSelect?${queryParams}`, this.options);
  }

  delOrderMetaUpload(queryParams: any): Observable<any> {
    this.pathUrl = this.router.url
    return this.http.delete(`${apiKSFServer}/api/v2/${this.pathUrl.includes('hop-dong-chuyen-doi/') ? 'orderconvert' : 'order'}/DelOrderMetaUpload?${queryParams}`, this.options);
  }

  getOrderPage(queryParams: any): Observable<any> {
    this.pathUrl = this.router.url
    return this.http.get(`${apiKSFServer}/api/v2/${this.pathUrl.includes('hop-dong-chuyen-doi/') ? 'orderconvert' : 'order'}/GetOrderPage?${queryParams}`, this.options);
  }

  getOrderContPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/ordercont/GetOrderContPage?${queryParams}`, this.options);
  }

  getOrderPriceCalcPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/order/GetOrderPriceCalcPage?${queryParams}`, this.options);
  }

  getVoucherPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/ordervoucher/GetVoucherPage?${queryParams}`, this.options);
  }

  getOrderContDelive(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/ordercont/GetOrderContDelive?${queryParams}`, this.options);
  }

  setOrderBook(queryParams: any): Observable<any> {
    this.pathUrl = this.router.url
    return this.http.post(`${apiKSFServer}/api/v2/${this.pathUrl.includes('hop-dong-chuyen-doi/') ? 'orderconvert' : 'order'}/SetOrderBook` ,queryParams, this.options);
  }

  SetOrderBondBook(queryParams: any): Observable<any> {
    this.pathUrl = this.router.url
    return this.http.post(`${apiKSFServer}/api/v2/order/SetOrderBondBook` ,queryParams, this.options);
  }

  setOrderContReceive(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/ordercont/SetOrderContReceive` ,queryParams, this.options);
  }

  setOrderContDelive(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/ordercont/SetOrderContDelive` ,queryParams, this.options);
  }

  setOrderUpgate(queryParams: any): Observable<any> {
    this.pathUrl = this.router.url
    return this.http.put(`${apiKSFServer}/api/v2/${this.pathUrl.includes('hop-dong-chuyen-doi/') ? 'orderconvert' : 'order'}/SetOrderUpgate` ,queryParams, this.options);
  }

  setOrderRoomChange(queryParams: any): Observable<any> {
    this.pathUrl = this.router.url
    return this.http.put(`${apiKSFServer}/api/v2/${this.pathUrl.includes('hop-dong-chuyen-doi/') ? 'orderconvert' : 'order'}/SetOrderRoomChange` ,queryParams, this.options);
  }

  getOrderInfo(queryParams: any): Observable<any> {
    this.pathUrl = this.router.url
    return this.http.get(`${apiKSFServer}/api/v2/${this.pathUrl.includes('hop-dong-chuyen-doi/') ? 'orderconvert' : 'order'}/GetOrderInfo?${queryParams}`, this.options);
  }
  
  getOrderFlow(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/order/GetOrderFlow?${queryParams}`, this.options);
  }

  setOrderNotify(queryParams: any): Observable<any> {
    this.pathUrl = this.router.url
    return this.http.post(`${apiKSFServer}/api/v2/${this.pathUrl.includes('hop-dong-chuyen-doi/') ? 'orderconvert' : 'order'}/setOrderNotify`, queryParams, this.options);
  }

  setOrderRelease(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/orderhold/SetOrderRelease`, queryParams, this.options);
  }

  setOrderPaymentRollback(queryParams: any): Observable<any> {
    this.pathUrl = this.router.url
    return this.http.put(`${apiKSFServer}/api/v2/${this.pathUrl.includes('hop-dong-chuyen-doi/') ? 'orderconvert' : 'order'}/SetOrderPaymentRollback`, queryParams, this.options);
  }

  setVoucherClean(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/ordervoucher/SetVoucherClean`, queryParams, this.options);
  }

  setVoucherPlan(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/ordervoucher/SetVoucherPlan`, queryParams, this.options);
  }

  setVoucherReceived(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/ordervoucher/SetVoucherReceived`, queryParams, this.options);
  }

  setPayApproveOfVoucher(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/ordervoucher/SetPayApproveOfVoucher`, queryParams, this.options);
  }

  setPayOnHalfOfVoucher(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/ordervoucher/SetPayOnHalfOfVoucher`, queryParams, this.options);
  }

  delOrder(queryParams: any): Observable<any> {
    this.pathUrl = this.router.url
    return this.http.delete(`${apiKSFServer}/api/v2/${this.pathUrl.includes('hop-dong-chuyen-doi/') ? 'orderconvert' : 'order'}/DelOrder?${queryParams}`, this.options);
  }

  setBondLiqRequest(queryParams: any): Observable<any> {
    this.pathUrl = this.router.url
    return this.http.put(`${apiKSFServer}/api/v2/${this.pathUrl.includes('hop-dong-chuyen-doi/') ? 'orderconvert' : 'order'}/SetBondLiqRequest`, queryParams, this.options);
  }

  setOrderPaymentApprove(queryParams: any): Observable<any> {
    this.pathUrl = this.router.url
    return this.http.put(`${apiKSFServer}/api/v2/${this.pathUrl.includes('hop-dong-chuyen-doi/') ? 'orderconvert' : 'order'}/SetOrderPaymentApprove`, queryParams, this.options);
  }

  setOrderReferralChange(queryParams: any): Observable<any> {
    this.pathUrl = this.router.url
    return this.http.put(`${apiKSFServer}/api/v2/${this.pathUrl.includes('hop-dong-chuyen-doi/') ? 'orderconvert' : 'order'}/SetOrderReferralChange`, queryParams, this.options);
  }

  //UInvInterest

  getOrderInterestPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/interest/GetOrderInterestPage?${queryParams}`, this.options);
  }

  setOrderInterestPlan(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/interest/SetOrderInterestPlan`, queryParams, this.options);
  }

  setPayOnHalfOfInterest(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/interest/SetPayOnHalfOfInterest` , queryParams, this.options);
  }

  //UInvWithdrawal

  getOrderWithdrawalPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/withdrawal/GetOrderWithdrawalPage?${queryParams}`, this.options);
  }

  getOrderWithdrawalFinalPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/withdrawal/GetOrderWithdrawalFinalPage?${queryParams}`, this.options);
  }

  getOrderWithdrawalInfo(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/withdrawal/GetOrderWithdrawalInfo?${queryParams}`, this.options);
  }

  delWithdrawal(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/withdrawal/DelWithdrawal?${queryParams}`, this.options);
  }

  setOrderWithdrawalDraft(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/withdrawal/SetOrderWithdrawalDraft`, queryParams, this.options);
  }

  setOrderWithdrawalInfo(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/withdrawal/SetOrderWithdrawalInfo`, queryParams, this.options);
  }

  setOrderWithdrawalSet(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/withdrawal/SetOrderWithdrawalSet`, queryParams, this.options);
  }

  //UInvLiquidation

  getLiquidationInfo(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/liquidation/GetLiquidationInfo?${queryParams}`, this.options);
  }

  getLiquidationPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/liquidation/GetLiquidationPage?${queryParams}`, this.options);
  }

  getOrderIntMethods(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/liquidation/GetOrderIntMethods?${queryParams}`, this.options);
  }

  delLiquidation(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/liquidation/DelLiquidation?${queryParams}`, this.options);
  }

  setLiquidationInfo(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/liquidation/SetLiquidationInfo`, queryParams, this.options);
  }

  setLiquidationDraft(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/liquidation/SetLiquidationDraft`, queryParams, this.options);
  }

  setLiquidationSet(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/liquidation/SetLiquidationSet`, queryParams, this.options);
  }

  setLiquidationChange(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/liquidation/SetLiquidationChange`, queryParams, this.options);
  }

  // UInvAgent

  getOrderAgentPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/orderagent/GetOrderAgentPage?${queryParams}`, this.options);
  }

  setOrderAgentCommit(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/orderagent/SetOrderAgentCommit`, queryParams, this.options);
  }

  setPayOnHalfOfAgent(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/orderagent/SetPayOnHalfOfAgent`, queryParams, this.options);
  }

 

  //UInvScheme

  getSchemePage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/scheme/GetSchemePage?${queryParams}`, this.options);
  }

  getSchemeInfo(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/scheme/GetSchemeInfo?${queryParams}`, this.options);
  }

  getSchemeList(): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/scheme/GetSchemeList`, this.options);
  }

  getSchemeTenor(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/scheme/GetSchemeTenor?${queryParams}`, this.options);
  }

  getSchemeTenorPage(queryParams): Observable<any> {
    return this.http.get(`${apiKSFBaseUrl}/api/v2/scheme/GetSchemeTenorPage?` + queryParams, this.options);
  }

  getSchemeTenorList(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/scheme/GetSchemeTenorList?${queryParams}`, this.options);
  }

  getConvertPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/scheme/GetConvertPage?${queryParams}`, this.options);
  }

  setOrderBenAccountChange(queryParams): Observable<any> {
    this.pathUrl = this.router.url
    return this.http.put<any>(`${apiKSFServer}/api/v2/${this.pathUrl.includes('hop-dong-chuyen-doi/') ? 'orderconvert' : 'order'}/SetOrderBenAccountChange`, queryParams, this.options);
  }

  getConvertInfo(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/scheme/GetConvertInfo?${queryParams}`, this.options);
  }

  getConvertList(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/scheme/GetConvertList?${queryParams}`, this.options);
  }

  getConvertDiscount(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/scheme/GetConvertDiscount?${queryParams}`, this.options);
  }

  delSchemeInfo(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/scheme/DelSchemeInfo?${queryParams}`, this.options);
  }

  delSchemeTenor(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/scheme/DelSchemeTenor?${queryParams}`, this.options);
  }

  delConvertInfo(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/scheme/DelConvertInfo?${queryParams}`, this.options);
  }

  delConvertDiscount(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/scheme/DelConvertDiscount?${queryParams}`, this.options);
  }

  setConvertDiscount(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/scheme/SetConvertDiscount`, queryParams, this.options);
  }

  setSchemeInfo(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/scheme/SetSchemeInfo`, queryParams, this.options);
  }

  setConvertInfo(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/scheme/SetConvertInfo`, queryParams, this.options);
  }

  setSchemeTenor(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/scheme/SetSchemeTenor`, queryParams, this.options);
  }

  setSchemeSubmit(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/scheme/SetSchemeSubmit`, queryParams, this.options);
  }

  // UInvAgency

  getAgencyPolicyPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/agency/GetAgencyPolicyPage?${queryParams}`, this.options);
  }

  getAgencyPolicyInfo(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/agency/GetAgencyPolicyInfo?${queryParams}`, this.options);
  }

  getAgencyPolicyShare(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/agency/GetAgencyPolicyShare?${queryParams}`, this.options);
  }

  getAgencyPolicyConvert(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/agency/GetAgencyPolicyConvert?${queryParams}`, this.options);
  }

  getAgencyPolicyList(): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/agency/GetAgencyPolicyList`, this.options);
  }

  setAgencyPolicyInfo(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/agency/SetAgencyPolicyInfo`, queryParams, this.options);
  }

  setAgencyPolicyConvert(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/agency/SetAgencyPolicyConvert`, queryParams, this.options);
  }

  setAgencyPolicyShare(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/agency/SetAgencyPolicyShare`, queryParams, this.options);
  }

  rejectAgencyPolicyInfo(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/agency/RejectAgencyPolicyInfo`, queryParams, this.options);
  }

  delAgencyPolicyInfo(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/agency/DelAgencyPolicyInfo?${queryParams}`, this.options);
  }

  delAgencyPolicyConvert(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/agency/DelAgencyPolicyConvert?${queryParams}`, this.options);
  }
  
  delAgencyPolicyShare(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/agency/DelAgencyPolicyShare?${queryParams}`, this.options);
  }

  // UmeUser

  getUserPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/account/GetUserPage?${queryParams}`, this.options);
  }

  getUserInfo(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v1/UmeUser/GetUserInfo?${queryParams}`, this.options);
  }

  getUserProfile(): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v1/UmeUser/GetUserProfile`, this.options);
  }

  //UInvAccount
 
  getAccountPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/account/GetAccountPage?${queryParams}`, this.options);
  }

  getBankList(): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coreaccount/GetBankList`, this.options)
  }

  getAccountInfo(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/account/GetAccountInfo?${queryParams}`, this.options);
  }

  delAccountInfo(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/account/DelAccountInfo?${queryParams}`, this.options);
  }

  setAccountInfo(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/account/SetAccountInfo`, queryParams, this.options);
  }

  setAccountStatus(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/account/SetAccountStatus`, queryParams, this.options);
  }
  
  //API GETINFO
  apiGetInfo(manhinh, url,queryParams): Observable<any> {
    if(manhinh === 'open') {
      return this.http.get(`${apiKSFBaseUrl}/api/v2/open/${url}?` + queryParams, this.options);
    }else {
      return this.http.get(`${apiKSFBaseUrl}/api/v2/product/${url}?` + queryParams, this.options);
    }
  }

  apiSetInfo(manhinh, url,queryParams): Observable<any> {
    if(manhinh === 'open') {
      return this.http.post(`${apiKSFBaseUrl}/api/v2/open/${url}` , queryParams, this.options);
    }else {
      return this.http.post(`${apiKSFBaseUrl}/api/v2/product/${url}` , queryParams, this.options);
    }
  }

  getRoomList(manhinh,queryParams): Observable<any> {
    if(manhinh === 'open') {
      return this.http.get<any>(`${apiKSFBaseUrl}/api/v2/open/GetRoomList?` + queryParams, this.options)
    }else {
      return this.http.get<any>(`${apiKSFBaseUrl}/api/v2/product/GetRoomList?` + queryParams, this.options)
    }
  }

  delOpenRoom(manhinh, queryParams): Observable<any> {
    if(manhinh === 'open') {
      return this.http.delete<any>(`${apiKSFBaseUrl}/api/v2/open/DelOpenRoom?` + queryParams, this.options)
    }else {
      return this.http.delete<any>(`${apiKSFBaseUrl}/api/v2/product/DelProdRoom?` + queryParams, this.options)
    }
  }

  setOpenRoomStatus(manhinh, queryParams): Observable<any> {
    if(manhinh === 'open') {
      return this.http.put<any>(`${apiKSFBaseUrl}/api/v2/open/SetOpenRoomStatus` , queryParams, this.options)
    }else {
      return this.http.put<any>(`${apiKSFBaseUrl}/api/v2/product/SetProdRoomStatus` , queryParams, this.options)
    }
  }

  setOpenRoomAdds(manhinh, queryParams): Observable<any> {
    if(manhinh === 'open') {
      return this.http.post<any>(`${apiKSFBaseUrl}/api/v2/open/SetOpenRoomAdds`, queryParams, this.options)
    }else {
      return this.http.post<any>(`${apiKSFBaseUrl}/api/v2/product/SetProdRoomAdds` ,queryParams, this.options)
    }
  }

  getProdPricePage(queryParams) {
    return this.http.get<any>(`${apiKSFBaseUrl}/api/v2/product/GetProdPricePage?` + queryParams, this.options)
  }

  getProdPriceCalcPage(path, queryParams) {
    return this.http.get<any>(`${apiKSFBaseUrl}/api/v2/product/${path}?` + queryParams, this.options)
  }

  getProdPriceInfo(queryParams) {
    return this.http.get<any>(`${apiKSFBaseUrl}/api/v2/product/GetProdPriceInfo?` + queryParams, this.options)
  }

  getProdPriceRoom(queryParams) {
    return this.http.get<any>(`${apiKSFBaseUrl}/api/v2/product/GetProdPriceRoom?` + queryParams, this.options)
  }

  delProdPriceInfo(queryParams) {
    return this.http.delete<any>(`${apiKSFBaseUrl}/api/v2/product/DelProdPriceInfo?` + queryParams, this.options)
  }

  setProdPriceInfo(queryParams) {
    return this.http.post<any>(`${apiKSFBaseUrl}/api/v2/product/SetProdPriceInfo` , queryParams, this.options)
  }

  setProdPriceRoomAdds(queryParams) {
    return this.http.post<any>(`${apiKSFBaseUrl}/api/v2/product/SetProdPriceRoomAdds` , queryParams, this.options)
  }

  setProdPriceStatus(queryParams) {
    return this.http.put<any>(`${apiKSFBaseUrl}/api/v2/product/SetProdPriceStatus` , queryParams, this.options)
  }

  setProdPriceCalc(queryParams) {
    return this.http.get<any>(`${apiKSFBaseUrl}/api/v2/product/SetProdPriceCalc?` + queryParams, this.options)
  }

  getProdPriceCalc(queryParams) {
    return this.http.get<any>(`${apiKSFBaseUrl}/api/v2/product/GetProdPriceCalc?` + queryParams, this.options)
  }

  getProdPriceRoomList(queryParams) {
    return this.http.get<any>(`${apiKSFBaseUrl}/api/v2/product/GetProdPriceRoomList?` + queryParams, this.options)
  }


  // UInvReport

  getReportList(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/report/GetReportList?${queryParams}`, this.options);
  }
  
  getTemplateReportPage(queryParams: string, url: string): Observable<any> {
    return this.http.get<any>(`${url}?${queryParams}`, this.options)
  }

  // UInvFinal

  getOrderFinalPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/final/GetOrderFinalPage?${queryParams}`, this.options);
  }

  getOrderFinalInfo(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/final/GetOrderFinalInfo?${queryParams}`, this.options);
  }

  getOrderHistoryPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/final/GetOrderHistoryPage?${queryParams}`, this.options);
  }

  setOrderFinalPlan(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/final/SetOrderFinalPlan`, queryParams, this.options);
  }

  delOrderFinalPlan(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/final/DelOrderFinalPlan?`+ queryParams, this.options);
  }

  setPayOnHalfOfFinal(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/final/SetPayOnHalfOfFinal`, queryParams, this.options);
  }

  setPayOnRollOfFinal(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/final/SetPayOnRollOfFinal`, queryParams, this.options);
  }
  

  getProfileByReferral(queryParams): Observable<any> {
    return this.http.get<any>(`${apiCoreBaseUrl}/api/v1/coreuser/GetProfileByReferral?` + queryParams, this.options);
  }

  MigrateContract(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/open/MigrateContract`, queryParams, this.options);
  }


  getOpenDetailV2(queryParams): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/open/GetOpenDetailV2?` + queryParams, this.options);
  }
  
  ReportMigrateContract(queryParams): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/order/ReportMigrateContract?` + queryParams, this.options);
  }
  
  
  // api Upload S3

  // uploadFileS3(data: any) : Observable<any> {
  //   const options = {
  //     headers: new HttpHeaders({
  //       Authorization: `Bearer ${JSON.parse(localStorage.getItem('access_token-s3'))}`,
  //       'accept': 'application/json',
  //     })
  //   };
  //   return this.http.post(`${apiUploadS3Url}/upload`, data, options);
  // }


  
  //UInvSchemeVoucher

  setOpenVoucher(queryParams): Observable<any> {
    return this.http.post(`${apiKSFBaseUrl}/api/v2/openvoucher/SetOpenVoucher`, queryParams, this.options);
  }

  getSchemeVoucherFilter(): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/schemevoucher/GetSchemeVoucherFilter`
    , this.options);
  }

  getSchemeVoucherPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/schemevoucher/GetSchemeVoucherPage?${queryParams}`
    , this.options);
  }

  getSchemeVoucherInfo(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/schemevoucher/GetSchemeVoucherInfo?${queryParams}`
    , this.options);
  }

  setSchemeVoucherInfo(queryParams: any): Observable<any> {
    return this.http.post(`${apiKSFServer}/api/v2/schemevoucher/SetSchemeVoucherInfo`, queryParams
    , this.options);
  }

  setSchemeVoucherSubmit(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/schemevoucher/SetSchemeVoucherSubmit`, queryParams
    , this.options);
  }

  setSchemeVoucherStatus(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/schemevoucher/SetSchemeVoucherStatus`, queryParams
    , this.options);
  }

  delSchemeVoucherInfo(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/schemevoucher/DelSchemeVoucherInfo?` + queryParams
    , this.options);
  }

  getSchemeVoucherList(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/schemevoucher/GetSchemeVoucherList?` + queryParams
    , this.options);
  }

 
  
// ksInvOrderRol
  getOrderRollInfo(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/orderroll/GetOrderRollInfo?${queryParams}`, this.options);
  }  

  getOrderRollPage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/orderroll/GetOrderRollPage?${queryParams}`, this.options);
  }

  getOrderRollSchemePage(queryParams: any): Observable<any> {
    return this.http.get(`${apiKSFServer}/api/v2/orderroll/GetOrderRollSchemePage?${queryParams}`, this.options);
  }

  setRollNoPayApprovedOfFinal(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/orderroll/SetRollNoPayApprovedOfFinal`, queryParams, this.options);
  }

  setPayOnRollOfFinalV2(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/orderroll/SetPayOnRollOfFinal`, queryParams, this.options);
  }

  setOrderRollSchemePage(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/orderroll/SetOrderRollSchemePage`, queryParams, this.options);
  }

  delOrderRollPlan(queryParams: any): Observable<any> {
    return this.http.delete(`${apiKSFServer}/api/v2/orderroll/DelOrderRollPlan?` +  queryParams, this.options);
  }

  setOrderRollPlan(queryParams: any): Observable<any> {
    return this.http.put(`${apiKSFServer}/api/v2/orderroll/SetOrderRollPlan` , queryParams, this.options);
  }
}

